<template>
    <div class="page">
        <div class="title">企业相关设置</div>

        <el-divider></el-divider>

        <el-form
            ref="form"
            :rules="rules"
            label-position="top"
            :model="form"
            label-width="80px"
        >
            <el-form-item label="加班工资基数" prop="salary_overtime_base">
                <el-input
                    v-model="form.salary_overtime_base"
                    type="number"
                ></el-input>
            </el-form-item>

            <el-form-item label="工资单标题" prop="salary_header">
                <el-input v-model="form.salary_header"></el-input>
            </el-form-item>

            <el-form-item label="预支经济补偿金" prop="salary_pre_header">
                <el-input v-model="form.salary_pre_header"></el-input>
            </el-form-item>

            <el-form-item label="迟到时间（分）" prop="punch_late">
                <el-input v-model="form.punch_late" type="number"></el-input>
                <div style="color: #999;">超过正常打卡n分钟算迟到</div>
            </el-form-item>

            <el-form-item label="旷工时间（分）" prop="punch_absenteeism">
                <el-input
                    v-model="form.punch_absenteeism"
                    type="number"
                ></el-input>
                <div style="color: #999;">迟到达n分钟算旷工</div>
            </el-form-item>

            <el-form-item
                label="合同超时提醒时长（分）"
                prop="contract_notify_timelong"
            >
                <el-input
                    v-model="form.contract_notify_timelong"
                    type="number"
                ></el-input>
            </el-form-item>

            <el-form-item class="form_btns">
                <div class="form_btns">
                    <div
                        class="custom_button plain no_select"
                        @click="onSubmit"
                    >
                        保存设置
                    </div>
                    <div class="custom_button no_select" @click="onReset">
                        重置
                    </div>
                </div>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    data() {
        const checkInt = (rule, value, callback) => {
            if (!(parseInt(value) >= 0))
                return callback(new Error("请填写正确的值"));
            callback();
        };
        return {
            form: {
                salary_overtime_base: "",
                salary_header: "",
                salary_pre_header: "",
                punch_late: "",
                punch_absenteeism: "",
                contract_notify_timelong: "",
            },
            rules: {
                salary_overtime_base: [
                    {
                        validator: checkInt,
                        trigger: "blur",
                    },
                ],
                punch_late: [
                    {
                        validator: checkInt,
                        trigger: "blur",
                    },
                ],
                punch_absenteeism: [
                    {
                        validator: checkInt,
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        this.getEnterpriseOtherSetting()
            .then((res) => {
                console.log(res);
                if (res.data.data.config.contract_notify_timelong)
                    res.data.data.config.contract_notify_timelong /= 60;
                this.form = res.data.data.config;
            })
            .catch((e) => {
                console.log(e);
            });
    },
    methods: {
        ...mapActions("enterprise", [
            "getEnterpriseOtherSetting",
            "postEnterpriseOtherSetting",
        ]),

        /**
         * 提交表单
         */
        onSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let loading = this.$loading();
                    console.log(this.form);
                    this.postEnterpriseOtherSetting(this.form)
                        .then((res) => {
                            console.log(res);
                            loading.close();
                            this.$message.success("信息提交成功");
                        })
                        .catch((e) => {
                            console.error(e);
                            loading.close();
                            this.$message.error("信息提交失败");
                        });
                } else {
                    this.$notify.error({
                        title: "提交失败",
                        message: "请先完整填写表单",
                    });
                    return false;
                }
            });
        },

        onReset() {
            this.$confirm("重置表单, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.$refs.form.resetFields();
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    font-size: 14px;
    font-weight: bold;
    color: #1c2438;
}

.add_photo {
    width: 100px;
    height: 100px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dcdfe6;
    border-radius: 3px;
    color: #dcdfe6;
}

::v-deep .el-form-item__label {
    font-size: 14px;
    color: #222222;
}

::v-deep .el-input__inner {
    width: 480px;
}

.el-input {
    width: 400px;
    height: 48px;
    font-size: 14px;
    color: #222222;
}

.form_btns {
    display: flex;
    align-items: center;

    .custom_button {
        width: 142px;
        height: 48px;
        line-height: 48px;
        font-size: 14px;
    }

    > div:not(:first-child) {
        margin-left: 20px;
    }
}
</style>
